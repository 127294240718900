<template>

	<modal ref="modal" :title="name" :subtitle="(position?(position+' - '):'') + affiliation">
		<div>
			<img class="bio" v-show="portrait" :src="picturespath + portrait"
			:alt="name" />
			<div v-html="bio"></div>
		</div>
		<div class="d-bio-talk" v-show="talk">
			<h3><span>Talk</span><br>{{talk}}</h3>
			<div v-html="abstract"></div>
		</div>
	</modal>

</template>

<script>

	import modal from './generalmodal.vue'

	export default {
		name: 'speakermodal',
		components: {
			modal: modal,
		},
		props: {
			picturespath: String
		},
		data: function(){
			return {
				showmodal: false,

				name: "",
				position: "",
				affiliation: "",
				portrait: "ph.jpg",
				bio: "",
				talk: "",
				abstract: "",
			};
		},
		created: function(){

		},
		computed: {

		},
		methods: {
			openModal: function (speaker) {
				this.name = speaker.name;
				this.position = speaker.position;
				this.affiliation = speaker.affiliation;
				this.portrait = speaker.portrait;
				this.bio = speaker.bio;
				this.talk = speaker.talk;
				this.abstract = speaker.abstract;
				this.showmodal = true;
				this.$refs.modal.openModal();

			},

			// closeModal: function () {
			// 	this.showmodal = false;
			// 	// this.$refs["speaker-modal"].style.display = "none";
			// 	this.$refs["modal-backdrop"].classList.remove("in");
			// 	document.body.classList.remove("modal-open");
			// 	document.removeEventListener('keydown',this.handleKey);
			// },
		},
	}

</script>

<style lang="less">


	.bio-dialog .container{
	/* display: none; */
		width: auto;
	}

	.bio-dialog h2 small{
		display: block;
		line-height: 1.3;
		margin-top: 0.2em;
	}

	img.bio {
		float: left;
		margin: 12px 15px 15px 0px;
		width: 180px;
		max-width: 90%;
		border-radius: 50%;
		box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.4);
		border: 2px solid #0A332B;
		@media (max-width: 500px){
			display: block;
			float: unset;
			margin-left: auto;
			margin-right: auto;
		}
	}

</style>
