<template>

	<!-- Video Modal -->
	<div id="video-details" v-if="showmodal" class="modal fade in">
		<div ref="modal-backdrop" class="modal-backdrop fade" @click="closeModal()"></div>
		<div class="modal-container">
			<div class="modal-dialog bio-dialog video-modal" role="dialog">
				<div ref="modal-dialog" class="modal-content">

					<div class="scrollable">

						<div class="close-modal" @click="closeModal()">
							<div class="box">
								<div class="lr">
									<div class="rl">
									</div>
								</div>
							</div>
						</div>

						<div class="modal-body" style="padding:10px;">
							<!-- <h3>Teaser&nbsp;+ Full&nbsp;Video</h3> -->
							<div v-if="g.unlocked">
								<div class="title" style="display: flex; flex-direction: row; align-items: flex-start;"><div style="flex: 10 1 auto;" v-html="title"></div></div>
								<div class="time">{{timeDisplay}}</div>

								<div class="center-content">
									<div style="width: 100%; max-width: 45em;">
										<div class="info" v-show="name || affiliation">
											<div class="text">
												<div class="presenter" v-if="item.chair"><strong>Chair:</strong></h3></div>
												<div class="presenter" v-if="name" v-html="name"></div>
												<div class="aff" v-if="affiliation" v-html="affiliation"></div>
											</div>
										</div>
										<div style="font-size: 2em; margin: 2em;">
											<div v-if="typeof slides=='string'">
												<a :href="g.config.slidefolder?g.config.slidefolder+'/' + slides:slides"><i class="fa fa-file-pdf"></i> Open Slides</a>
											</div>
											<div v-else-if="Array.isArray(slides)">
												<ul>
													<li v-for="s of slides"><a :href="g.config.slidefolder?g.config.slidefolder+'/' + s.file:s.file">Slides: {{ s.name }}</a></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<unlock></unlock>
							<div style="width: 100%; text-align: center; font-size: 120%" v-if="!g.unlocked"><p style="text-align: center;">Participants of the conference have been provided the password by email.</p></div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" tabindex="0" @click="closeModal()">Close</button>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

	import unlock from "../core/components/unlock.vue";


	export default {
		name: 'slidemodal',
		components: {
			unlock: unlock,
		},
		data: function(){
			return {
				showmodal: false,
				item: {},

				title: "",
				timeDisplay: "",

				name: "",
				affiliation: "",

				slides: "",
			};
		},
		created: function(){

		},
		computed: {

		},
		methods: {
			openModal: function( item ){

				this.item = item
				this.title = this.item.title



				if (item.time) this.timeDisplay = (new Date(this.item.time)).toLocaleString('en-gb', { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' });

				this.name = "";
				this.affiliation = "";
				if (item.chair){
					this.name = item.chair.name;
					this.affiliation = item.chair.affiliation;
				} else {
					if (item.speaker) this.name = item.speaker;
					if (item.affiliation) this.affiliation = item.affiliation;
				}
				this.slides = item.slides;
				this.showmodal = true;

				document.body.classList.add("modal-open");

				let self = this;
				this.$nextTick(()=>{
					setTimeout(()=>self.$refs["modal-backdrop"].classList.add("in"),50);
				});

				document.addEventListener('keydown',this.handleKey);

			},
			handleKey: function(e){
				if (e.key == "Escape") this.closeModal();
			},

			closeModal: function () {
				this.showmodal = false;
				// this.$refs["speaker-modal"].style.display = "none";
				this.$refs["modal-backdrop"].classList.remove("in");
				document.body.classList.remove("modal-open");
				document.removeEventListener('keydown',this.handleKey);
			},
		},
	}

</script>

<style lang="less">

.modal-dialog.video-modal{
	.title{
		margin-top: 0.5em;
		font-size: 1.5em;
		padding-bottom: 8px;
		@media (max-width: 500px) {
			font-size: 1.2em;
		}
		border-bottom: 2px solid #e5e5e5;
		padding-bottom: 3px;

		padding-right: 1.3em;


		// &::before{
		// 	display: inline-block;
		// 	margin-right: 0.5em;
		// 	content: "\f008";
		// 	font-family: "FontAwesome";
		// }
	}
	.time{
		margin-top: 0.3em;
		color: gray;
		margin-bottom: 1em;
	}

	h3{
		font-size: 16px;
	}

	.info{
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;

		padding: 10px;
		margin-top: 0.5em;
		border-radius: 5px;
		// box-shadow: 0 0 5px 0px rgba(0,0,0,0.2);
		box-shadow: 0 0 1px 2px #18bc9c8c;

		@media (max-width: 500px) {
			flex-direction: column;
			align-items: flex-start;

		}

		flex-wrap: nowrap;
		.text{
			flex: 10 1 auto;
			.presenter,.aff{
				//margin-left: 1.5em;
			}
			.aff{
				font-weight: bold;
			}
		}

		.logo{
			flex: 0 0 auto;
			margin-left: 1em;
			img{
				max-height: 5em;
				max-width: 8em;
			 }
			@media (max-width: 500px){

				margin-top: 1em;
				//margin-bottom: 0.5em;
				margin-left: 1.5em;
			}
		}
	}

	.language{
		display: block;
		margin-top: 0.5em;
		color: gray;
		&::before{
			content: "\f028";
			font-family: "FontAwesome";
			margin-right: 0.5em;
		}
	}



}



</style>
