

import Vue from 'vue'
import App from './main.vue'

import sharedDataMixin from './mixin-shared-data.js';



Vue.config.productionTip = false
window.Vue = Vue;

Vue.mixin(sharedDataMixin);

new Vue({
  render: h => h(App),
}).$mount('#app');
