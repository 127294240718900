<template>
  <div id="app">
	<videomodal ref="videomodal" />
	<slidemodal ref="slidemodal" />

	<navigation :menu="g.content.menu" :dispNow="dispNow" :getArgument="getArgument"></navigation>

	<!-- Time Setting -->
	<div id="timeInput" v-if="dispTimeInput_$">
		<div>
			<button @click="g.limitedCountry=!g.limitedCountry"><span v-show="g.limitedCountry">!</span>China</button>
			<button @click="g.unlocked=!g.unlocked"><span v-show="!g.unlocked">Unl</span><span v-show="g.unlocked">L</span>ock</button>
			<button @click="timeInput_$ = g.config.gotoStartButtonTime">goto Start</button>
			Page at: {{(new Date(g.time + ( (new Date()).getTimezoneOffset() + 60 )*60*1000)).toLocaleString('de')}}
			<!-- <label style="margin-left: 0.5em; margin-right: 0.3em" for="time"></label> -->
			<input id="time"
				@keydown.up.stop.exact="incTime(10)"
				@keydown.alt.up.stop.exact="incTime(1)"
				@keydown.shift.up.stop.exact="incTime(60)"
				@keydown.alt.down.stop.exact="incTime(-1)"
				@keydown.shift.down.stop.exact="incTime(-50)"
				@keydown.down.stop.exact="incTime(-10)"
				v-model="timeInput_$"></div>
	</div>
	<!-- /.container-fluid -->


	<mainheader :header="g.content.header" :dispNow="dispNow" :getArgument="getArgument"></mainheader>

	<!-- Sections -->
	<section v-for="section of visibleSections" :id="section.name" :key="section.name"
		class="text-center"
		:class="{'success': getArgument(section.background) && ( getArgument(section.background)=='dark' || getArgument(section.background)=='venue' ) ,'venue_section': getArgument(section.background) && getArgument(section.background)=='venue'}">

		<h1 v-if="getArgument(section.title) && dispNow(section.title)">{{getArgument(section.title)}}</h1>
		<faruler v-if="getArgument(section.title) && dispNow(section.title)"></faruler>

		<!-- <hr v-if="getArgument(section.title)" :class="{'star-primary': !getArgument(section.background) || getArgument(section.background) == 'light', 'star-light': getArgument(section.background) == 'dark'}"> -->

		<template v-for="(item,index) of section.content">
			<div style="margin-bottom: 2em" v-if="(!item.type || !item.type.includes('component'))" :key="index">
				<dynamic ref="dynamic" :template="getContentHTML(item)"></dynamic>
			</div>

			<div v-if="item.type=='component:sponsors' && dispNow(item)" :key="index">
				<sponsors ref="sponsors" :getLiveView="getLiveView"></sponsors>
			</div>

			<div v-if="item.type=='component:speakers' && dispNow(item)" :key="index">
				<div class="on-page-cms" style="position: absolute;" v-if="cmsAvailable_$ && !getLiveView()">
					<input id="shcms" v-model="showCmsControls_$" type="checkbox"><label for="shcms"> Show CMS Controls</label>
				</div>
				<speakers ref="speakers" json="data/speakers.json" pictures="data/speakers/" :showCmsControls_$="showCmsControls_$" :getLiveView="getLiveView"></speakers>
			</div>

			<div v-if="item.type=='component:organizers' && dispNow(item)" :key="index">
				<organizers></organizers>
			</div>

		</template>
	</section>

	<!-- Footer -->
	<footer class="text-center">
		<div class="footer-below">
			<div class="footer-menu container">
				<a v-if="g.legal" href="" @click.stop.prevent="showLegal()">Impressum/Datenschutz</a>
				<a v-if="g.accessibility" href="" @click.stop.prevent="showAccessibility()">Accessibility</a>

			</div>
			<div class="container copyright">
				{{g.content.copyrightText}} {{(new Date()).getYear()+1900}}
				<br>
			</div>
		</div>
	</footer>
	<generalmodal ref="gmodal" :title="g.modalTitle" :subtitle="g.modalSubTitle">
		<div v-html="g.modalContent"></div>
	</generalmodal>
  </div>
</template>

<script>



// Components
import dynamic from './components/dynamicHTML.vue';
import mainHeader from  './components/header.vue';
import navigation from  './components/nav.vue';
import sponsors from  './components/sponsors.vue';
import speakers from  './components/speakers.vue';
import organizers from  './components/organizers.vue';
import faruler from './components/fa-ruler.vue';
import generalmodal from './components/generalmodal.vue';
import videomodal from '../components/videomodal.vue';
import slidemodal from '../components/slidemodal.vue';
// Config
import {hooks} from '../logic.js';

// User Logic/Data


export default {
	name: 'App',

	components:
		{
			dynamic: dynamic,
			mainheader: mainHeader,
			navigation: navigation,
			sponsors: sponsors,
			speakers: speakers,
			organizers: organizers,
			generalmodal: generalmodal,
			videomodal,
			slidemodal,
			faruler: faruler,
		},
	data: function(){
		return {
			dispTimeInput_$: false,
			timeInput_$:"",

			cmsAvailable_$: window.parent.cms ? true : false,
			showCmsControls_$: false,

			$timeupdateTimeoutHandle: null,
			$timeupdateIntervalHandle: null,
			$reloadIntervalHandle: null,

			hooks_$: hooks,


	  	};
	},
	created: function(){

		if (this.hooks_$.created) this.hooks_$.created.bind(this)();
	},
	destroyed() {
		this.clearIntervals();
	},
  	mounted: function(){
		let self = this;

		this.g.videomodal = this.$refs.videomodal;
		this.g.slidemodal = this.$refs.slidemodal;

		console.log("Page starting at ",(new Date(self.g.time)).toLocaleString('de'));

		this.dispTimeInput_$ = this.atTestLocation;
		this.timeInput_$ = this.formatedTime();

		initBootStrap();
		this.unhideAll();



		// TIME LOGIC UPDATE INTERVALS

		let now = this.g.time; // Date.now??
		let offset = -1000;
		let nextIntervalStart = (Math.floor(now / (this.g.config.intervalUpdateTimeLogicS*1000)) + 1) * this.g.config.intervalUpdateTimeLogicS*1000 + offset;
		let wait = nextIntervalStart - now;


		// Time Update
		this.$timeupdateTimeoutHandle = setTimeout(() => {
			function fn(){
				if ( Math.abs(Date.now - self.g.time) > 5 * self.g.config.intervalUpdateTimeLogicS*1000 ){
					self.g.time = self.g.time + self.g.config.intervalUpdateTimeLogicS*1000; // Ensure IN correct interval
				}else self.g.time = Date.now() + self.g.config.intervalUpdateTimeLogicS/2*1000; // Ensure IN correct interval
				if (self.atTestLocation) self.g.time += self.g.time_offset || 0;
				self.g.schedule.time = self.g.time;
				console.log("Page at: ",(new Date(self.g.time)).toLocaleString('de'));

				self.$forceUpdate();
				self.unhideAll();
				self.$timeupdateTimeoutHandle = null;
			}

			self.$timeupdateIntervalHandle = setInterval(() => {
				if (self.hooks_$.beforeTimeUpdate) self.hooks_$.beforeTimeUpdate.bind(self)();
				fn();
				if (self.hooks_$.afterTimeUpdate) self.$nextTick(self.hooks_$.afterTimeUpdate.bind(self));

			}, this.g.config.intervalUpdateTimeLogicS*1000);

			if (self.hooks_$.beforeTimeUpdate) self.hooks_$.beforeTimeUpdate.bind(self)();
			fn(true);
			if (self.hooks_$.afterTimeUpdate) self.$nextTick(self.hooks_$.afterTimeUpdate.bind(self));

		}, wait);

		// Hooks relative to time update
		if (this.hooks_$.relativeToTimeUpdate){
		 	for (let hook of this.hooks_$.relativeToTimeUpdate){
		 		let w = wait + hook.offset;
		 		if (w < 0) w = w + this.g.config.intervalUpdateTimeLogicS * 1000;
		 		hook.$timeoutHandle = setTimeout(() => {
					hook.fn.bind(self)();
					hook.$timeoutHandle = null;
		 			hook.$intervalHandle = setInterval(() => {
		 			 	hook.fn.bind(self)();
		 			}, self.g.config.intervalUpdateTimeLogicS*1000);
		 		}, w);
		 	}
		}

		//// RELOAD CONTENT MECHANISM:

		let nextReload = Date.now()+this.g.config.intervalReloadContentsMin*60*1000;
		console.log("Next Content Reload: ", (new Date(nextReload)).toLocaleString('de'));

		this.$reloadIntervalHandle = setInterval(function(){
			if (self.hooks_$.beforeContentReload) self.hooks_$.beforeContentReload.bind(self)();

			let p1 = self.reloadContent();
			let p2 = self.reloadJSON();

			Promise.all([p1,p2]).then(()=>{
				if (self.hooks_$.afterContentReload) self.$nextTick(self.hooks_$.afterContentReload.bind(self));
				console.log("Contents Reloaded!");
				console.log("Next Content Reload: ",(new Date(Date.now()+self.g.config.intervalReloadContentsMin*60*1000)).toLocaleString('de'));
			});
		}, this.g.config.intervalReloadContentsMin * 60 * 1000);


		// CMS events
		if (window.parent.cms) {
			document.body.addEventListener("keyup", this.handleKeyUp);
			document.body.addEventListener("keydown", this.handleKeyDown);
		}

		// Mounted Hook
		if (this.hooks_$.mounted) this.hooks_$.mounted.bind(self)();

	},
	computed: {
		atTestLocation: function(){
			let loc = window.location.toString();
			if (loc){
				for (let l of this.g.config.testLocations) if (loc.includes(l)) return true;
			}
			return false;
		 },
		 visibleSections: function(){
			 let res = [];
			 for (let section of this.g.content.sections)	 if (this.dispNow(section)) res.push(section);
			 return res;
		 },
	},
	watch: {
		"g.openModal": function(){
			if ( this.g.openModal ) this.$refs.gmodal.openModal()
			this.$forceUpdate()
			this.g.openModal = false
		},
		timeInput_$: function() {
			let self = this;
			const dateParser = /\s*(\d{1,2})\.(\d{1,2})\.(\d{4})\s*,?\s*(\d{1,2}):(\d{1,2}):(\d{1,2}).*/;
			let match = this.timeInput_$.match(dateParser);
			// console.log(match);
			if ( match ){
				let year = parseInt(match[3]), month = parseInt(match[2])-1, day=parseInt(match[1]), hour=parseInt(match[4]), minute=parseInt(match[5]), second=parseInt(match[6]);

				let nt = (new Date(year,month,day,hour,minute,second)).getTime();
				console.log(nt, this.g.time)
				//if (this.g.time != nt){
					//this.clearIntervals();
					console.log("Page at: ",(new Date(this.g.time)).toLocaleString('de'));
					this.g.time = nt;
					this.g.time_offset = nt - Date.now();
					this.$set(self.g.schedule,"time", this.g.time);
				//}


			}else {
				console.log("Date NaN");
			}
		},
		"g.time": function(){
			console.log("gtime" ,this.g.time)
		},
		"g.unlocked": function(){
			console.log("Security State: ", this.g.unlocked?"Unlocked":"Locked");
		}

	},
  	methods: {

		showLegal: function(){
			this.g.modalTitle = "Legal";
			this.g.modalSubTitle = "Impressum/Datenschutz";
			this.g.modalContent = this.g.legal;
			this.$refs.gmodal.openModal();
			this.$forceUpdate();
		},
		showAccessibility: function(){
			this.g.modalTitle = "Accessibility";
			this.g.modalSubTitle = "Accessibility Statement";
			this.g.modalContent = this.g.accessibility;
			this.$refs.gmodal.openModal();
			this.$forceUpdate();
		},
		// Clears all Intervals for update logic and reload logic
		clearIntervals: function(){
			if (this.$timeupdateIntervalHandle) clearInterval(this.$timeupdateIntervalHandle);
			if (this.$timeupdateTimeoutHandle) clearTimeout(this.$timeupdateTimeoutHandle);
			if (this.hooks_$.relativeToTimeUpdate) {
				for (let hook of this.hooks_$.relativeToTimeUpdate) {
					if (hook.$intervalHandle) clearInterval(hook.$intervalHandle);
					if (hook.$timeoutHandle) clearTimeout(hook.$timeoutHandle);
				}
			}
			if (this.$reloadIntervalHandle) clearInterval(this.$reloadIntervalHandle);
		},

		incTime: function(minutes){

			this.g.time += minutes*60*1000;
			this.$set(this.g.schedule,"time", this.g.time);
			this.timeInput_$ = this.formatedTime(new Date(this.g.time));
			//this.$forceUpdate();
		},

		formatedTime: function(date){
			if (!date) date = (new Date(this.g.time)).toLocaleString('de');
			return date.toLocaleString('de');
		},

		unhideAll: function(){
			for (let item of document.querySelectorAll(".hide-while-loading")){
				item.classList.remove("hide-while-loading");
				item.style.visibility = "visible";
			}
		},

		reloadJSON: async function () {
			//console.debug(this.$refs.speakers);
			if (this.$refs.speakers) await this.$refs.speakers[0].reloadJSON();
			if (this.$refs.sponsors) await this.$refs.sponsors[0].reloadJSON();
		},

		reloadContent: async function(){
			let self = this

			console.log("reloading Content");
			let response = await fetch("contentDoNotEdit.json?" + Date.now())
			let data = await response.json()

			self.assignNewContent_$(data);
			//self.$forceUpdate();
		},

		// CMS:
		getLiveView: function () {
			if (this.cmsAvailable_$ && window.parent.cms.liveView) return true;
			else return false;
		},

		handleKeyDown: function (e) {
			if (window.parent.cms) {
				window.parent.cms.handleKeyDown(e);
			}
		},
		handleKeyUp: function (e) {
			if (window.parent.cms) {
				window.parent.cms.handleKeyUp(e);
			}
		},


		// Generate HTML From Content.js

		dispNow: function(item){
		//if (item.type && item.type == )


			if (typeof item == 'object'){
				var now = this.g.time;
				var res = true;
				if (item.from) res = res && (now >= Date.parse(item.from + this.g.config.utcoffset));
					// if (item.text=='Home' && item.target) console.log("FROM: " ,now,Date.parse(item.when.from + "+0200"), res);
				if (item.till) res = res && (now < Date.parse(item.till + this.g.config.utcoffset));
					// if (item.text=='Home' && item.target && item.when.till) console.log("FROM: " ,now,Date.parse(item.when.till + "+0200"), res);

				if (item.value && item.value.toString().includes('TEST CONTENT')) console.log("Test Content Displayed: ",res);
				return res;
			}else return true;

		},

		getArgument: function(arg){
			if (!arg) return null;
			if (typeof arg == "object"){
				if (arg instanceof Array){
					for (let a of arg){
						if (this.dispNow(a) === true) return a.value;
					}
					return null;
				}else{
					if (this.dispNow(arg) === true) return arg.value;
					else return null;
				}

			}
			return arg;
		},

		// Convert content of content.js to HTML
		getContentHTML: function(content, level) {

			if (!level) level = 1;
			let res = "";
			// console.log(JSON.stringify(content instanceof Array,null,2));
			// console.log(JSON.stringify(content,null,2));

			if (typeof content == 'object'){
				if (content instanceof Array){
					for (let item of content){
						res += this.getContentHTML(item,level);
					}
				}else{

					let style = "";
					let classes = "";

					// Generate Container Element
					if (level == 1 && content.type != "cards"){
						if (!content.class || !content.class.includes("container")) content.class = addClass(content.class,"container");
					}
					if (content.class) classes = content.class;
					if (content.style) style = content.style;

					if ( this.dispNow(content)){

						if (!content.type || content.type == "html"){

							if (content.title) res += "<h" + level + ">" + content.title + "</h" + level + ">";
							res += wrap(this.getContentHTML(content.value,level+1), "div",{class: classes,style: style});
						}else if (content.type == "expandable"){
							res += wrap(this.getContentHTML(content.content,level+1),"expandable",{title: content.title, class: classes, style: style});
						}else if (content.type == "cards" || content.type == "cards-light"){
							res += '<div class="cards">';
							for (let c of content.cards){
								if (this.dispNow(c)){
									res += '<div class="card' + ((content.type=="cards-light")?' light':'') +  '"><h3>' + c.card + '</h3>';
									res += this.getContentHTML(c.content,level+1);
									res += '</div>'
								}
							}
							res += '</div>'
						}else if (content.type == "table"){ // Table HTML
							let tablestr = "";
							let iRow = 0;

							for (let row of content.table){

								let inner = "calc(100% - " + 4 * (row.length+1) + "px)";
								let countAuto;
								if (content.widths && content.widths instanceof Array){
									countAuto = 0;
									for (let w of content.widths){
										if (w != "auto") inner = "calc(" + inner + " - " + w + ")";
										else countAuto++;
									}
								}
								if (!countAuto) countAuto = row.length;

								let rowstr = "";
								let iCol = 0;

								for (let cl of row){

									let colClass = "cl";
									if (cl.colClass && cl.colClass[iCol]) colClass = addClass(colClass,cl.colClass[iCol]);
									if (cl.cellClass) colClass = addClass(colClass,cl.cellClass);

									let colStyle="";
									let width;

									if (content.widths && content.widths[iCol]) width = content.widths[iCol];
									else if (content.widths && typeof content.widths == "string") width = content.widths;
									else width = "auto";

									if (width != 'auto'){
										colStyle = addStyle(colStyle,"flex: 0 0 auto; width: " + width);
									}else {

										colStyle = addStyle(colStyle,"flex: 0 0 auto; width: calc(" + inner + " / " + countAuto + ")");
									}

									if (content.colStyle && content.colStyle[iCol]) colStyle += addStyle(colStyle,content.colStyle[iCol]);
									if (content.cellStyle) colStyle += addStyle(colStyle,content.cellStyle);

									rowstr += wrap(wrap(this.getContentHTML(cl,level+1),"div"),"div",{class: colClass,style: colStyle});
									iCol++;
								}

								let rowClass = "rw";
								let rowStyle = "";
								if (content.rowClass && content.rowClass[iRow]) rowClass = addClass(rowClass,content.rowClass[iRow]);
								if (content.rowStyle && content.rowStyle[iRow]) rowStyle = addStyle(rowStyle,content.rowStyle[iRow]);
								tablestr += wrap(rowstr,"div",{class: rowClass, style: rowStyle});
								iRow++;
							}

							let tableClass = "tbl";
							let tableStyle = "";
							if (content.class) tableClass = addClass(tableClass,content.class);
							if (content.style) tableStyle = content.style;

							tablestr = wrap(tablestr,"div",{class: tableClass, style: tableStyle});
							if (level==1) tablestr = wrap(tablestr,"div",{class: "container"});

							// console.log(tablestr);
							res += tablestr;

						}else{
							if (content.value) res += content.value;
						}

					}
				}
			} else if (typeof content == "string"){
				res = content;
			}

			// console.log("RETURNED: ", res);
			// if (res && res.includes("TEST CONTENT") && content.type) {
			// 	console.log("HAIKSOIDJOSIDJ");
			// 	console.log(res);
			// }
			return res;

			function addClass(org,classes){
				if (!org) return classes;
				return (org.trim() + " " + classes.trim()).trim();
			}
			function addStyle(org, style){
				//console.log(org,style);
				if (!org) return style;
				if (!org.endsWith(";")) org+=";";
				return org + style;
			}

			function wrap(val,tag,args){

				let res = "<" + tag;
				for (let a in args){
					res += " " + a + '="' + args[a] + '"';
				}
				res += ">" + val + "</" + tag + ">";
				// console.log(res);
				return res;

			}


		},



	}
}

function initBootStrap(){
	// jQuery for page scrolling feature - requires jQuery Easing plugin
	$(function () {
		$("body").on("click", ".page-scroll a", function (event) {
			var $anchor = $(this);
			$("html, body")
			.stop()
			.animate(
				{
					scrollTop: $($anchor.attr("href")).offset().top,
				},
				1500,
				"easeInOutExpo"
				);
				event.preventDefault();
		});
	});


	// Highlight the top nav as scrolling occurs
	$("body").scrollspy({
		target: ".navbar-fixed-top",
	});

	// Closes the Responsive Menu on Menu Item Click
	$(".navbar-collapse ul li a").click(function () {
		$(".navbar-toggle:visible").click();
	});

}

</script>

<style lang="less">

	// @import "./style/font-awesome.min.css";
	// @import "./style/bootstrap.min.css";
	// @import "../style-variables.less";

	@import "./style/main.less";

	div#timeInput {
		z-index: 100000000;
		position: fixed;
		right: 0; top: 0;
		text-align: right;
		div{
			background-color: gray;
			font-size: 0.8em;
			padding-left: 0.3em;

			input{
				width: 11em;
				text-align: center;
			}

		}
	}

	.on-page-cms{

		z-index: 5;
		label{
			margin-left: 0.5em;
		}
		input{
			margin-left: 1em;
		}
		&.sponsor-ctrl, &.speaker-ctrl{
			font-size: 2rem;
			line-height: 1;
			padding: 5px;
			padding-left: 7px;
			padding-top: 7px;
			border-radius: 50%;
			cursor: pointer;
			display: inline-block;
			&:hover{
				color: black;
				background-color: desaturate(orange,5%);
			}
		}

		&.sponsor-ctrl{
			margin-left: 5px;
			background-color: fade(desaturate(orange,5%),40%);
			border: 1px solid #d2d2d2;
		}

		&.speaker-ctrl{
			position: absolute;
			background-color: fade(desaturate(orange,5%),20%);
			top: -2px;
			left: -2px;
			border: 1px solid lightgray;
			color: #eee;
		}
	}

	footer {
	    color: @footer-text;
	}

	footer h3 {
		margin-bottom: 30px;
	}

	footer .footer-above {
		padding-top: 50px;
		background-color: #233140;
	}

	footer .footer-col {
		margin-bottom: 50px;
	}

	footer .footer-below {
		padding: 25px 0;
		// background-color: #233140;
		background-color: @footer;
		box-shadow: 0px -3px 2px 0px rgba(255,255,255,0.2);

	}

	footer a{
		color: @footer-links !important;
		display: inline-block;
		margin-left: 0.5em;
		margin-right: 0.5em;
	}


	footer .footer-menu{
		margin-bottom: 0.5em;
	}

</style>
